import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { OrderComponent } from '../signup/order.component';
import { ManageProductComponent } from '../material-component/manage-product/manage-product.component';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  url = environment.order;
  reviews = environment.apiUrlProduct
  update: any;
  userDisplayName: any;
  data: any;  
 
  constructor(private httpClient: HttpClient) { }



  getProducts(data: any) {
    const token = localStorage.getItem('token');
    return this.httpClient.get(this.url + `orders?page=${data}`, {
      headers: new HttpHeaders().set('Content-Type', "application/json").set('cache-control', 'no-cache').set("Authorization", "Bearer " + token)
    })
  }

  // getsubchild() { 
  // const token = localStorage.getItem('token_subscribe');
  // this.data = localStorage.getItem('user');
  //    this.userDisplayName =JSON.parse( this.data)
  //     var id=this.userDisplayName.review_id;
  
  // return this.httpClient.get(this.reviews + `user/sub-users?id=${id}`, {
  //   headers: new HttpHeaders().set('Content-Type', "application/json").set('cache-control', 'no-cache').set("Authorization", "Bearer " + token)
  // })
  // }
  getsubchild() { 
  const token = localStorage.getItem('token');
  this.data = localStorage.getItem('user');
     this.userDisplayName =JSON.parse( this.data)
      var id=this.userDisplayName.review_id;
  
  return this.httpClient.get(this.url + `users`, {
    headers: new HttpHeaders().set('Content-Type', "application/json").set('cache-control', 'no-cache').set("Authorization", "Bearer " + token)
  })
  }

  activestatus(data:any){
    const token=localStorage.getItem('token');
// console.log(data);

   return this.httpClient.get(this.reviews+`activate-user/${data}`,{
     headers:new HttpHeaders().set('Content-Type',"application/json").set("Authorization", "Bearer "+token)
   })
 }
}