export const environment = {
  production: false,
  // apiUrlProduct:'https://reviews.mrmmbs.com/api/',
  apiUrlProduct:'https://app.my-reviews.com/api/',
  
//   // apiUrl:'https://selling.mrmmbs.com/api/',
  order:'https://admin.my-reviews.com/api/',
//   username:'narender.mrmmbs@gmail.com',
//   Password: 'B128A76EC07A70E6EB31D2D69AFED8F6FC88',

// Port: 2525
// apiUrlProduct:'https://review.test/api/',
// apiUrl:'https://selling.test/api/',
// order:'https://selling.test/api/',

};