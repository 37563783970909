<!-- {{userDisplayName}} -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <mat-icon>account_circle</mat-icon>
    <!-- <div class="username">{{userDisplayName}}</div> -->
    
</button>

<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item (click)="changePassword()">
        <mat-icon>password</mat-icon>Change Password
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>Sign Out
    </button>
 
    <button mat-menu-item (click)="UserProfile()">
        <mat-icon>man</mat-icon>User Profile
    </button>
    
    
</mat-menu>