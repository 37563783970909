<br><br><br><br><br>
<div class="col-md-12  form">

<div class="login">
  <div class="login-header">
    <p class="img"><img src="../../assets/img/logo-def.png" /></p>
    
    
  </div>
</div>


<mat-dialog-content class="formsdiv mat-typography">
  
  <form [formGroup]="loginForm">
    
      <div class="maindiv" mat-form-field-infix fxLayout="column"> 
        <h4 align="center" class="colored">Please enter your credentials to login.</h4>
        <mat-label></mat-label>
        <mat-form-field appearance="fill" fxFlex>
          
          <input matInput placeholder="User Name" formControlName="email" required>
          
          <mat-error *ngIf="loginForm.controls['email'].touched && loginForm.controls.email.invalid">
            <span *ngIf="loginForm.controls['email'].error"></span>
            <span>This field is mandatory.</span>
        </mat-error>
        
      </mat-form-field> 
    
      <mat-label></mat-label>
          <mat-form-field appearance="fill" fxFlex>
             
              <input matInput placeholder="Password"  [type]="hide ? 'password' : 'text'"  formControlName="password" type="password" required>
              <button class="viewbutton" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="loginForm.controls['password'].touched && loginForm.controls.password.invalid">
                <span *ngIf="loginForm.controls['password'].error"></span>
                <span>This field is mandatory.</span>
            </mat-error>
            </mat-form-field>
            <mat-dialog-actions class="submit" align="center">  
              <button mat-raised-button  type="submit" (click)="handleSubmit()  "  [disabled]="!(loginForm.valid && loginForm.dirty)"><b class="font">Login</b></button>
           
            
            </mat-dialog-actions>



            
      </div>
      
  </form>
  
</mat-dialog-content>

</div>