import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { GlobalConstant } from 'src/app/shared/global-constant';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
 changePasswordForm:any=FormGroup;
 responseMessage:any;
  userDisplayName: any;
data:any
  constructor(private formBuilder:FormBuilder,
    private userService:UserService,
    public dialogRef:MatDialogRef<ChangePasswordComponent>,
    private ngxService:NgxUiLoaderService,
    private snakbarService:SnackbarService) { }

  ngOnInit(): void {
    this.changePasswordForm=this.formBuilder.group({
      newPassword:[null,[Validators.required]],
      confirmPassword:[null,[Validators.required]],
    }
    )
    this.data = localStorage.getItem('user');
    this.userDisplayName =JSON.parse( this.data)
    console.log(this.userDisplayName);
  }
  validateSubmit(){
    if(this.changePasswordForm.controls['newPassword'].value != this.changePasswordForm.controls['confirmPassword'].value){
      return true;
    }
    else{
      return false;
    }
  }
handleChangePasswordSubmit(){
  this.ngxService.start();
  var formData=this.changePasswordForm.value;
  
  var data={
    // newPassword:formData.newPassword,
    password:formData.confirmPassword
  }
    this.userService.edituserdetail(this.userDisplayName.id,data).subscribe((response:any)=>{
      console.log('this is change password change msg',response);
      
      this.ngxService.stop();
      this.responseMessage="Password Change successfully";
      this.dialogRef.close();
      this.snakbarService.openSnackBar(this.responseMessage,"")
    },(error)=>{
      console.log(error);
      this.ngxService.stop();
      if(error.error?.message){
        this.responseMessage = error.error?.message;
      }
      else{
        this.responseMessage=GlobalConstant.genericError;
      }
      this.snakbarService.openSnackBar(this.responseMessage,GlobalConstant.error)
    })
}
}
