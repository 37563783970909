import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from 'src/app/material-component/dialog/change-password/change-password.component';
import { ConfirmationComponent } from 'src/app/material-component/dialog/confirmation/confirmation.component';
import { ProfileComponent } from 'src/app/material-component/dialog/profile/profile.component';
// import { OrderComponent } from 'src/app/signup/order.component';
// import { CreateOrderComponent } from 'src/app/material-component/dialog/create-order/create-order.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  role: any;
  userDisplayName: string | null;
  constructor(private router: Router,
    private dialog: MatDialog) { 
    this.userDisplayName = localStorage.getItem('loggedUser');

  }
  logout(){
    const dialogConfig=new MatDialogConfig();
    dialogConfig.width="450px";
    dialogConfig.data={
      
      message:'Logout'
    };
    const dialogRef=this.dialog.open(ConfirmationComponent,dialogConfig);
    const sub=dialogRef.componentInstance.onEmitStatusChange.subscribe((user)=>{
      dialogRef.close();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['/'])
    })
  }
 
  changePassword(){
    const dialogConfig=new MatDialogConfig();
    dialogConfig.width="290px";
    
    this.dialog.open(ChangePasswordComponent,dialogConfig)
  }

  UserProfile(){
    this.router.navigate(['/profile']);
  }
    
  }

