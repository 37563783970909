import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material-module';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './shared/shared.module';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgxUiLoaderModule,NgxUiLoaderConfig,SPINNER,PB_DIRECTION} from "ngx-ui-loader";
import { TokenInterceptorInterceptor } from './services/token-interceptor.interceptor';
import { MatTableModule } from '@angular/material/table';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


const ngxUiLoaderConfig:NgxUiLoaderConfig={
text:"Loading...",
textColor:"#FFFFFF",
textPosition:"center-center",
pbColor:"green",
bgsColor:"#127ec3",  
fgsColor:"#127ec3",
fgsType:SPINNER.ballSpinClockwise,
fgsSize:20,
pbDirection:PB_DIRECTION.leftToRight,
pbThickness:3,

}

@NgModule({
  declarations: [	
    AppComponent,
    HomeComponent,
    FullComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    // OrderComponent,
    
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MaterialModule,
    FlexLayoutModule,
    SharedModule,
    HttpClientModule,
    CKEditorModule,
   

     NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  providers: [HttpClientModule,{provide:HTTP_INTERCEPTORS,useClass:TokenInterceptorInterceptor,multi:true}],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
