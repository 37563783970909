import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { FullComponent } from './layouts/full/full.component';

import { RouteGuardService } from './services/route-guard.service';

const routes: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'register', component: RegisterComponent },
  {
    path: '',//user
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',//user/dashboard
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren:
          () => import('./material-component/material.module').then(m => m.MaterialComponentsModule),
          // canActivate:[RouteGuardService],
          // data:{
          //   expectedRole:['admin','user']
          // }
        },
      {
        path: 'dashboard',
        component:DashboardComponent,
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        // canActivate:[RouteGuardService],
          // data:{
          //   expectedRole:['admin','user']
          // }
      }
    ]
  },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
