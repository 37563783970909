import { HttpClient, HttpHeaders } from '@angular/common/http';
import { convertUpdateArguments } from '@angular/compiler/src/compiler_util/expression_converter';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  // url=environment.apiUrl;
  urlProduct=environment.apiUrlProduct;
  neworder=environment.order

  constructor(private httpClient:HttpClient) { }

  add(data:any){
    return this.httpClient.post(this.urlProduct+"subscription-plans",data,{
      headers:new HttpHeaders().set('Content-Type',"application/json")    })
  }

update(data:any){
  return this.httpClient.patch(this.neworder+"/category/update/",data,{
    headers:new HttpHeaders().set('Content-Type',"application/json")
  })
}
  getCategory(){
   
    const token_subscribe =localStorage.getItem('token_subscribe');
    // console.log(token)

    return this.httpClient.get(this.urlProduct+'subscription-plans',{
      headers:new HttpHeaders().set('Content-Type',"application/json").set("Authorization", "Bearer "+token_subscribe)
    })
  }
  getview(data:any){
    
    // getProducts(data:any){  
      const token=localStorage.getItem('token');
      return this.httpClient.get(this.neworder+`orders/${data}`,{
        headers:new HttpHeaders().set('Content-Type',"application/json").set( 'cache-control', 'no-cache' ).set("Authorization", "Bearer "+token)
      })
    }

  getviewUser(data:any){
    
    // getProducts(data:any){  
      const token=localStorage.getItem('token');
      return this.httpClient.get(this.neworder+`users/${data}`,{
        headers:new HttpHeaders().set('Content-Type',"application/json").set( 'cache-control', 'no-cache' ).set("Authorization", "Bearer "+token)
      })
    }
}   