<body class="bgn">
    <p class="head">Dashboard</p>
   
    <br>

       <div class="row justify-content-center">
            <div class="col-sm-6 col-md-5">
                <div class="box">
                    <h2 class="title">total Order List</h2>
                    <p class="val">{{orderdata}}</p>
                    <p><button class="button" [routerLink]="['/orderlist']">View List</button></p>
                </div>
            </div>
            <div class="col-sm-6 col-md-5 ">
                <div class="box">
                    <h2 class="title">Total Sub User</h2>
                    <p class="val">{{childs}}</p>
                    <p><button class="button" [routerLink]="['/users']">View Customer List</button></p>
                </div>
            </div>
           
       </div>
</body>