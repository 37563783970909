import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
	getCategory() {
		throw new Error('Method not implemented.');
	}
  url=environment.order;
  constructor(private httpClient:HttpClient) { }

getDetails(){
  const token=localStorage.getItem('token');
  return this.httpClient.get(this.url+"orders",{
    headers:new HttpHeaders().set('Content-Type',"application/json").set( 'cache-control', 'no-cache' ).set("Authorization", "Bearer "+token)
  })}
}