import { Injectable } from "@angular/core";

export interface Menu{
    state:string;
    name:string;
    icon:string;
    role:string;
}


const MENUITEMS=[
    {state:'dashboard',name:'Dashboard',icon:'dashboard',role:''},
    {state:'subscriptionlist',name:'Subscription List',icon:'subscriptions',role:''},
    {state:'orderlist',name:'Order List',icon:'add_shopping_cart',role:''},
    {state:'users',name:'Customer List',icon:'account_box',role:''},
    {state:'register',name:'Create New',icon:'add',role:''},

    // {state:'profile',name:'Edit User',icon:'edit',role:''}



];
@Injectable()
export class MenuItem{
    getMenuitem():Menu[]{
        return MENUITEMS;
    }
}