<div class="main-container">
    <mat-toolbar class="topbartelative">
        <div class="navbar-fixed-top">
            <img src="../../../favicon.ico" height="50" width="100">
            <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed" style="vertical-align: bottom;">
                <mat-icon>menu</mat-icon>
            </button><b>
                <span style="vertical-align: middle;">
                    <!-- <mat-icon>storefront</mat-icon> -->
                </span>
            </b>
           
         
        </div>
        <span fxFlex></span>
        <app-header></app-header>
    </mat-toolbar>
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'"
            fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches">
            <app-sidebar></app-sidebar>
        </mat-sidenav>
        <mat-sidenav-content class="page-wrapper">
            <div class="page-content">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>