<div class="settool">
<mat-toolbar color="primary">
    <mat-toolbar-row fxLayout="row">
        <span class="title-center">Change Password</span>
    </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="changePasswordForm">
        <div fxFlex fxLayout="column">
      
            <div fxLayout="row wrap">
                <!-- <mat-label></mat-label> -->
        
                <mat-form-field appearance="fill" fxFlex>
                    <input matInput placeholder="New Password" formControlName="newPassword" required>
                    <mat-error *ngIf="changePasswordForm.controls.newPassword.touched && changePasswordForm.controls.newPassword.invalid">
                        <span *ngIf="changePasswordForm.controls.newPassword.errors.required">This field is mandatory.</span>
                        <span *ngIf="changePasswordForm.controls.newPassword.errors.patern">This field is invalid</span> 
                    </mat-error>
                </mat-form-field>
             
            </div>
            <div fxLayout="row wrap">
                <!-- <mat-label></mat-label> -->
        
                <mat-form-field appearance="fill" fxFlex>
                    <input matInput placeholder="Confirm Password" formControlName="confirmPassword" required>
                    <mat-error *ngIf="changePasswordForm.controls.confirmPassword.touched && changePasswordForm.controls.confirmPassword.invalid">
                        <span *ngIf="changePasswordForm.controls.confirmPassword.errors.required">This field is mandatory.</span>
                        <span *ngIf="changePasswordForm.controls.confirmPassword.errors.patern">This field is invalid</span> 
                    </mat-error>
                </mat-form-field>
             
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button color="primary" type="Submit" (click)="handleChangePasswordSubmit()" [disabled]=" validateSubmit() || !(changePasswordForm.valid && changePasswordForm.dirty)">Update</button>
    <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>
</div>

