
<div class="fixed-w">
<mat-dialog-content class="mat-typography">
    <h5>Are you sure want to {{details.message}}</h5>
</mat-dialog-content>
<div class="setb">
<mat-dialog-actions class="abc" align=""end>
    <button mat-raised-button color="primary" (click)="handleChangeAction()">Yes</button>
    <button mat-raised-button mat-dialog-close="">No</button>
</mat-dialog-actions>
</div>
</div>