import { Component, AfterViewInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DashboardService } from '../services/dashboard.service';
import { SnackbarService } from '../services/snackbar.service';
import { GlobalConstant } from '../shared/global-constant';
import { CategoryService } from 'src/app/services/category.service';
import { ProductService } from '../services/product.service';
// import {}
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
},
)
export class DashboardComponent implements AfterViewInit {
	responseMessage:any;
	data:any;
	orderdata:any
childs:any
	ngAfterViewInit() { }

	constructor(private dashboardService:DashboardService,
		
		private ngxService:NgxUiLoaderService,
		private snackbarService:SnackbarService,
		private categoryService:CategoryService,
		private  productService:ProductService,
		){
			this.ngxService.start();

			// this.dashboardData()
			this.productdata()
			// this.Users()
			// this.tableData()
			// this.forstatus()
	}
	productdata() {
		this.productService.getProducts(this.orderdata).subscribe((response:any)=>{
			this.ngxService.stop();
			this.orderdata=response.order.total;
		
		})
		this.dashboardData()
	}
	dashboardData()
		{
			this.categoryService.getCategory().subscribe((response:any)=>{
				this.ngxService.stop();
				this.data=response.length;
			
			})
			this.Users()
		}
		Users()
		{
		
			this.productService.getsubchild().subscribe((response: any) => {
				this.ngxService.stop();
				this.childs=response.users.length
			// console.log(" total number",this.childs);
			
			})
			this.tableData()
		}
		tableData() {
			this.categoryService.getCategory().subscribe((response:any)=>{
			  this.ngxService.stop();
			//   this.dataSource=new MatTableDataSource(response);
			//   console.log(response);
			  var name={
				n1:response[0].name,
				n2:response[1].name,
				n3:response[2].name,
				n4:response[3].name,
		
			  }
			  
			  sessionStorage.setItem('subscription',  JSON.stringify(name));
			  
			  
			},(error:any)=>{
			  this.ngxService.stop();
			  if(error.error?.message){
				this.responseMessage=error.error?.message;
			  }
			  else{
				this.responseMessage=GlobalConstant.genericError;
			  }
			  this.snackbarService.openSnackBar(this.responseMessage,GlobalConstant.error);
			})
			this.forstatus()
		  }
		forstatus(){
			
  
				this.productService.getsubchild().subscribe((response: any) => {
				  localStorage.setItem('status',JSON.stringify(response.status))
				//   console.log(response)
		})}}
			// this.snackbarService.openSnackBar(this.responseMessage,GlobalConstant.error)
			
		
	

