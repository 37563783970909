import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  //url=environment.apiUrl;
  url2 = environment.apiUrlProduct;
  neworder = environment.order
  constructor(private httpClient: HttpClient) { }
  register(data: any) {
    const token = localStorage.getItem('token');
   // var fd = new FormData();
  //  fd.append('dd',data);
    return this.httpClient.post(this.neworder + "register", data, {
      headers: new HttpHeaders().set('accept', "application/json").set("Authorization", "Bearer " + token)

    })
  }
  order(data: any) {
    console.log(data);
    
    const token = localStorage.getItem('token');

    return this.httpClient.post(this.neworder + "orders", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json").set("Authorization", "Bearer " + token)
    })
  }
  getCategory(){
   
    const token_subscribe =localStorage.getItem('token_subscribe');
    // console.log(token)

    return this.httpClient.get(this.url2+'subscription-plans',{
      headers:new HttpHeaders().set('Content-Type',"application/json").set("Authorization", "Bearer "+token_subscribe)
    })
  }
  country() {
    console.log();
    
    const token = localStorage.getItem('token');

    return this.httpClient.get(this.neworder + "country", {
      headers: new HttpHeaders().set('Content-Type', "application/json").set("Authorization", "Bearer " + token)
    })
  }

  edituserdetail(id: any, data: any) {
    const token = localStorage.getItem('token');
    // console.log(token)

    return this.httpClient.post(this.neworder + "user-update/" + id, data, {
      headers: new HttpHeaders().set('accept', "application/json").set("Authorization", "Bearer " + token)
    })

  }
  

  activestatus(data: any) {
    const token = localStorage.getItem('token');
    // console.log(data);
    let params = new HttpParams();
    params = params.append('id', data.id);
    params = params.append('status', data.status);

    return this.httpClient.post(this.neworder + 'user-update/'+data.id,data, {
      headers: new HttpHeaders().set('accept', "application/json").set("Authorization", "Bearer " + token)
    })
    //this.activestatusreview(data);
    //return fg;
  }

  activestatusreview(data: any) {
    const token = localStorage.getItem('token_subscribe');
   
    let params1 = new HttpParams();
    params1 = params1.append('id', data.review_id);
    params1 = params1.append('status', data.status);

    return this.httpClient.get(this.url2 + 'activate-user?' + params1, {
      headers: new HttpHeaders().set('Content-Type', "application/json").set("Authorization", "Bearer " + token)
    })
  }

  activestatus2(data: any) {
    // console.log("status ", data);

    const token = localStorage.getItem('token_subscribe');
    // console.log(data);
    let params = new HttpParams();
    params = params.append('id', data.id);
    params = params.append('status', data.status);

    return this.httpClient.get(this.url2 + 'activate-user?' + params, {
      headers: new HttpHeaders().set('Content-Type', "application/json").set("Authorization", "Bearer " + token)
    })
  }
  getorder() {
    const token_subscribe = localStorage.getItem('token_subscribe');
    // console.log(token)

    return this.httpClient.get(this.neworder + 'subscription-plans', {
      headers: new HttpHeaders().set('Content-Type', "application/json").set("Authorization", "Bearer " + token_subscribe)
    })
  }
  //   createOrder(data:any){
  //     return this.httpClient.post(this.neworder+"register",data,{
  //       headers:new HttpHeaders().set('Content-Type',"application/json")
  //   })
  // }
  //  forgotPasssword(data:any){
  //     return this.httpClient.post(this.neworder+"/user/forgotPassword",data,{
  //       headers:new HttpHeaders().set("Content-Type","application/json")
  //     })
  //   }

  login(data: any) {

    return this.httpClient.post(this.neworder + "login", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")

    })
  }
  admin(data: any) {
// console.log(data);

    return this.httpClient.post(this.url2 + "sso/get-token", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")

    })
  }
  checkToken() {
    return this.httpClient.get(this.neworder + "register");
  }
  // changePassword(data:any){
  //   return this.httpClient.post(this.neworder+'/user/changePassword',data,{
  //     headers:new HttpHeaders().set('Content-Type',"application/json")
  //   })
  // }
}
