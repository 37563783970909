export class GlobalConstant{
    //Message
    public static genericError:string="Somthing went worng. please try again later "
    public static lasttable:string="This is last table"
    public static unauthroized: string="You are not authorized person to access this page."
    //Regex
    public static nameRegex:string="[a-zA-Z0-9 ]*";
    public static emailRegex:string="[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}";
    public static contactNumberRegex:string="^[e0-9]{10,10}$";

  //variable
  public static error:string ="error"
}