import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { emit } from 'process';

import { CategoryComponent } from '../material-component/dialog/category/category.component';

import { SnackbarService } from '../services/snackbar.service';
import { UserService } from '../services/user.service';
import { GlobalConstant } from '../shared/global-constant';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  hide:any
  loginForm: any = FormGroup;
  responseMessage: any;
  u:any;
  uq:any
  
  constructor(
    private dialog: MatDialog,
    private router:Router,
    private userService:UserService,
    private formBuilder: FormBuilder,
    

    private ngxService: NgxUiLoaderService,
    private snackbarService: SnackbarService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required]],
      
      password: [null, Validators.required]
    });
 
  }
  signupAction() {
    this.router.navigate(['/register']);
  }
 
  handleSubmit() {
     this.ngxService.start();
    var formData = this.loginForm.value;
    var data = {
      email: formData.email,
      password: formData.password,
      user: formData.user,
      
    }
    this.userService.login(data).subscribe((response: any) => {
      
      this.admin(response.user,data)
      this.ngxService.stop();
     
      localStorage.setItem('token', response.token);
      localStorage.setItem('user', JSON.stringify(response.user));
      
    }, (error) => {
      this.ngxService.stop();
      if (error.error?.message) {
        this.responseMessage = error.error?.message;
      }
      else {
        this.responseMessage = GlobalConstant.genericError;
      }
      this.snackbarService.openSnackBar(this.responseMessage, GlobalConstant.error)
    })
    
    
  }
  admin(data:any,password:any){
  
    var data1={
    username:data.company_name.replaceAll(' ','_')+'_'+data.zipcode.replaceAll(' ','_'),
//    password:password.password
  }
  this.userService.admin(data1).subscribe((response: any) => {
    
    this.ngxService.stop();
    // localStorage.setItem('token_subscribe', response.token);
    this.responseMessage="Login successfully ";
      
      this.snackbarService.openSnackBar(this.responseMessage,"")
    this.router.navigate(['/dashboard']);//user/dashboard
    localStorage.setItem('token_subscribe', response.token);
  }, (error) => {
    this.ngxService.stop();
    if (error.error?.message) {
      this.responseMessage = error.error?.message;
    }
    else {
      this.responseMessage = GlobalConstant.genericError;
    }
    this.snackbarService.openSnackBar(this.responseMessage, GlobalConstant.error)
  })
  }

}


